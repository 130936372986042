@import ../../sass/colors

$linkColor: $white

#header-navigation
    display: flex !important
    justify-content: space-between !important
    width: 100%
    padding: 0
    list-style: none
    text-decoration: none

    a:focus
        outline: none !important

    #brand
        background: $white
        padding: 3px
        max-height: 38px
        border-radius: 4px
        user-select: none !important

    #logo
        max-height: 32px
        max-width: 128px

    .nav-item
        .active
            border-bottom: solid $heimerl-red 2px !important
        .nav-link
            text-decoration: none
            color: $white
            border-bottom: solid $invisible 2px
            transition: all 0.5s ease

            &:hover
                border-bottom: solid $heimerl-red 2px

#header-navbar-toggler
    margin-right: 1%
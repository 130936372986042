@import ../../sass/colors

#footer
    display: flex
    position: absolute
    bottom: 0
    left: 0
    right: 0
    width: 100%
    min-height: 5vh !important
    padding: 0.5%
    background: $heimerl-green
    color: $white
    box-shadow: 0px -2px 10px 1px rgba(0,0,0,0.5)
@import ../../../sass/colors

.customers-table
    height: 70vh
    max-height: 70vh !important

.customer-bar
    display: flex
    justify-content: space-between

.optional
    border-color: blue !important
@import ../../../sass/colors

#sampling
    overflow-x: hidden
    overflow-y: scroll
    padding-left: 2.5%
    padding-right: 2.5%
    padding-bottom: 2%

    .even-node
        background: rgba(255, 255, 255, 0.75)

    .odd-node
        background: rgba(215, 215, 215, 0.75)

    .list-group-item
        padding: 5px
        border-radius: 0
        width: 100% !important

    .collapse
        border: 0

    .node-partly-sampled
        color: $heimerl-yellow !important

    .node-not-sampled
        color: $heimerl-red !important

    .node-sampled
        color: $heimerl-green !important

    .node-state-unknown
        color: grey !important

    .treenode-title
        background: rgba(255,255,255,0) !important
        text-align: left !important
        color: black !important
        font-weight: bold !important
        border: 0 !important
        box-shadow: none !important

.sampling-bar
    padding-left: 2.5%
    padding-right: 2.5%
    display: flex
    justify-content: space-between

#tree-box
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    width: 100%

    @media screen and (max-width: 600px)
        flex-direction: column
        overflow-y: scroll !important
        max-height: 85vh !important

        #navtree
            margin-bottom: 2.5%
            border-bottom: 3px dashed rgba(0, 0, 0, 0.5)
        #navtree, #datatree
            width: 100% !important

    #navtree, #datatree
        overflow: scroll !important
        overflow-x: hidden !important
        max-height: 72.5vh !important
        padding: 0.75%
    
    #navtree
        width: 40%
    
    #datatree
        width: 60%

#sampling-legend
    .text-red
        color: $heimerl-red
    .text-yellow
        color: $heimerl-yellow
    .text-green
        color: $heimerl-green

#flag-buttons
    width: 100% !important
    button
        width: 100% !important
        margin-bottom: 2% !important
    @media screen and (max-width: 600px)
        flex-direction: column !important
        border-radius: 4px !important
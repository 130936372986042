@import ../../../sass/colors

#login-mask
    display: flex
    flex-flow: column
    align-content: center
    width: 100%
    padding: 2.5%
    margin: auto

    @media screen and (max-height: 500px)
        padding: 0

    @media screen and (min-width: 900px)
        width: 45%
        padding: 1%

    form
        width: 85% !important
        margin: auto
        padding: 5%
        background: rgba(255, 255, 255, 0.5)
        border-radius: 8px
        box-shadow: 0px 3px 20px 0px

        @media screen and (min-width: 1024px)
            width: 100%

        @media screen and (max-height: 500px)
            padding: 2%

    input
        border: solid darken($white, 35%) 1px

    select
        text-align: center !important
        text-align-last: center !important

    .row
        @media screen and (max-width: 500px)
            display: block !important
        .col
            width: 100% !important
            @media screen and (max-width: 500px)
                margin-top: 2%
            button
                width: 100% !important

#heimerl-banner
    pointer-events: none
    user-select: none
    width: 100%
    margin: auto
    padding: 2.5%
    padding-bottom: 5%

    @media screen and (max-height: 500px)
        display: none
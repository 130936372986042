@import ./app/sass/colors
    
*
    margin: 0
    padding: 0
    
html, body
    margin: 0 !important
    padding: 0 !important
    width: 100vw !important
    height: 100vh !important
    overflow-x: hidden !important

body
    //text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.25)
    background-color: $globalBackgroundColor

body::before
    z-index: -1
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    content: ''
    background: url("./app/images/background.jpg") no-repeat center center fixed
    background-size: cover
    opacity: 0.3

#page-container
    padding: 1%

.normalized
    margin: 0
    padding: 0

.centered
    text-align: center

.scrollable
    overflow-y: scroll

/* React Table Styling */

$background: transparentize($white, 0.25)

.ReactTable
    color: black
    .rt-table
        background-color: $background

        .rt-thead
            font-weight: bold
            border-bottom: solid white 1px

        .rt-tbody
            overflow-x: hidden !important

    .pagination-bottom
        background-color: $background

    td, th
        border: 1px solid $white
@import ../../../sass/colors

#masterdata
    min-height: 70vh !important
    max-height: 75vh !important
    overflow: scroll !important
    overflow-x: hidden !important
    padding-left: 2.5%
    padding-right: 2.5%
    padding-bottom: 2%

    .even-node
        background: rgba(255, 255, 255, 0.75)

    .odd-node
        background: rgba(215, 215, 215, 0.75)

    .list-group-item
        padding: 5px
        border-radius: 0
        overflow: hidden !important

    .collapse
        border: 0

    .icon-detail
        color: grey !important

    .icon-cross
        color: blue !important

    .icon-category
        color: $heimerl-red !important

    .icon-masterdata
        color: $heimerl-green !important


    .treenode-title
        background: rgba(255,255,255,0) !important
        text-align: left !important
        color: $heimerl-green !important
        font-weight: bold !important
        border: 0 !important
        box-shadow: none !important

    .toolbar
        & > *
            margin-left: 2.5px
            margin-right: 2.5px
        display: flex
        align-items: right
        align-self: right
        align-content: right

    .fa-tree 
        pointer-events: none

.masterdata-bar
    padding-left: 2.5%
    padding-right: 2.5%
    display: flex
    justify-content: space-between

#flag-buttons
    width: 100% !important
    button
        width: 100% !important
        margin-bottom: 2% !important
    @media screen and (max-width: 600px)
        flex-direction: column !important
        border-radius: 4px !important

#index-buttons
    padding-right: 16px
* {
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  overflow-x: hidden !important;
}

body {
  background-color: rgba(0, 0, 0, 0.3);
}

body::before {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: url(/static/media/background.e72fb38f.jpg) no-repeat center center fixed;
  background-size: cover;
  opacity: 0.3;
}

#page-container {
  padding: 1%;
}

.normalized {
  margin: 0;
  padding: 0;
}

.centered {
  text-align: center;
}

.scrollable {
  overflow-y: scroll;
}

/* React Table Styling */
.ReactTable {
  color: black;
}
.ReactTable .rt-table {
  background-color: rgba(255, 255, 255, 0.75);
}
.ReactTable .rt-table .rt-thead {
  font-weight: bold;
  border-bottom: solid white 1px;
}
.ReactTable .rt-table .rt-tbody {
  overflow-x: hidden !important;
}
.ReactTable .pagination-bottom {
  background-color: rgba(255, 255, 255, 0.75);
}
.ReactTable td, .ReactTable th {
  border: 1px solid white;
}
#header {
  display: flex;
  width: 100%;
  padding: 0.5%;
  background: #006A4D;
  color: white;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5);
}
#header-navigation {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
#header-navigation a:focus {
  outline: none !important;
}
#header-navigation #brand {
  background: white;
  padding: 3px;
  max-height: 38px;
  border-radius: 4px;
  -webkit-user-select: none !important;
          user-select: none !important;
}
#header-navigation #logo {
  max-height: 32px;
  max-width: 128px;
}
#header-navigation .nav-item .active {
  border-bottom: solid #B5152A 2px !important;
}
#header-navigation .nav-item .nav-link {
  text-decoration: none;
  color: white;
  border-bottom: solid rgba(0, 0, 0, 0) 2px;
  transition: all 0.5s ease;
}
#header-navigation .nav-item .nav-link:hover {
  border-bottom: solid #B5152A 2px;
}

#header-navbar-toggler {
  margin-right: 1%;
}
#search {
  padding-right: 16px;
}

#search-bar {
  -webkit-user-select: none !important;
          user-select: none !important;
}

#search-button {
  color: #6c757d;
  background-color: white;
  border-color: white;
  border-left: 1px white solid;
  -webkit-user-select: none !important;
          user-select: none !important;
}
#footer {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 5vh !important;
  padding: 0.5%;
  background: #006A4D;
  color: white;
  box-shadow: 0px -2px 10px 1px rgba(0, 0, 0, 0.5);
}
#footer-navigation {
  width: 100%;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
#footer-navigation .nav-item {
  padding-left: 1%;
}
#footer-navigation .nav-item .nav-link {
  width: 100% !important;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  padding: 0;
  color: white;
  border-bottom: solid rgba(0, 0, 0, 0) 2px;
  transition: all 0.5s ease;
}
#footer-navigation .nav-item .nav-link:hover {
  border-bottom: solid #B5152A 2px;
}
.projects-table {
  height: 70vh;
  max-height: 70vh !important;
}

.project-bar {
  display: flex;
  justify-content: space-between;
}
.customers-table {
  height: 70vh;
  max-height: 70vh !important;
}

.customer-bar {
  display: flex;
  justify-content: space-between;
}

.optional {
  border-color: blue !important;
}
#sampling {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-bottom: 2%;
}
#sampling .even-node {
  background: rgba(255, 255, 255, 0.75);
}
#sampling .odd-node {
  background: rgba(215, 215, 215, 0.75);
}
#sampling .list-group-item {
  padding: 5px;
  border-radius: 0;
  width: 100% !important;
}
#sampling .collapse {
  border: 0;
}
#sampling .node-partly-sampled {
  color: #e6e600 !important;
}
#sampling .node-not-sampled {
  color: #B5152A !important;
}
#sampling .node-sampled {
  color: #006A4D !important;
}
#sampling .node-state-unknown {
  color: grey !important;
}
#sampling .treenode-title {
  background: rgba(255, 255, 255, 0) !important;
  text-align: left !important;
  color: black !important;
  font-weight: bold !important;
  border: 0 !important;
  box-shadow: none !important;
}

.sampling-bar {
  padding-left: 2.5%;
  padding-right: 2.5%;
  display: flex;
  justify-content: space-between;
}

#tree-box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #tree-box {
    flex-direction: column;
    overflow-y: scroll !important;
    max-height: 85vh !important;
  }
  #tree-box #navtree {
    margin-bottom: 2.5%;
    border-bottom: 3px dashed rgba(0, 0, 0, 0.5);
  }
  #tree-box #navtree, #tree-box #datatree {
    width: 100% !important;
  }
}
#tree-box #navtree, #tree-box #datatree {
  overflow: scroll !important;
  overflow-x: hidden !important;
  max-height: 72.5vh !important;
  padding: 0.75%;
}
#tree-box #navtree {
  width: 40%;
}
#tree-box #datatree {
  width: 60%;
}

#sampling-legend .text-red {
  color: #B5152A;
}
#sampling-legend .text-yellow {
  color: #e6e600;
}
#sampling-legend .text-green {
  color: #006A4D;
}

#flag-buttons {
  width: 100% !important;
}
#flag-buttons button {
  width: 100% !important;
  margin-bottom: 2% !important;
}
@media screen and (max-width: 600px) {
  #flag-buttons {
    flex-direction: column !important;
    border-radius: 4px !important;
  }
}
#users-list {
  padding-left: 10%;
  padding-right: 10%;
  margin: auto;
  max-height: 75vh;
}
#users-list .list-group {
  overflow-y: scroll !important;
  max-height: 75vh;
}
#users-list .hamburger-bun {
  background: rgba(255, 255, 255, 0.75) !important;
}
#users-list .user-enabled {
  color: #003728 !important;
  background: #b8d5cd !important;
}
#users-list .user-disabled {
  color: #5e0b16 !important;
  background: #eabdc3 !important;
}

#masterdata {
  min-height: 70vh !important;
  max-height: 75vh !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-bottom: 2%;
}
#masterdata .even-node {
  background: rgba(255, 255, 255, 0.75);
}
#masterdata .odd-node {
  background: rgba(215, 215, 215, 0.75);
}
#masterdata .list-group-item {
  padding: 5px;
  border-radius: 0;
  overflow: hidden !important;
}
#masterdata .collapse {
  border: 0;
}
#masterdata .icon-detail {
  color: grey !important;
}
#masterdata .icon-cross {
  color: blue !important;
}
#masterdata .icon-category {
  color: #B5152A !important;
}
#masterdata .icon-masterdata {
  color: #006A4D !important;
}
#masterdata .treenode-title {
  background: rgba(255, 255, 255, 0) !important;
  text-align: left !important;
  color: #006A4D !important;
  font-weight: bold !important;
  border: 0 !important;
  box-shadow: none !important;
}
#masterdata .toolbar {
  display: flex;
  align-items: right;
  align-self: right;
  align-content: right;
}
#masterdata .toolbar > * {
  margin-left: 2.5px;
  margin-right: 2.5px;
}
#masterdata .fa-tree {
  pointer-events: none;
}

.masterdata-bar {
  padding-left: 2.5%;
  padding-right: 2.5%;
  display: flex;
  justify-content: space-between;
}

#flag-buttons {
  width: 100% !important;
}
#flag-buttons button {
  width: 100% !important;
  margin-bottom: 2% !important;
}
@media screen and (max-width: 600px) {
  #flag-buttons {
    flex-direction: column !important;
    border-radius: 4px !important;
  }
}

#index-buttons {
  padding-right: 16px;
}
#login-mask {
  display: flex;
  flex-flow: column;
  align-content: center;
  width: 100%;
  padding: 2.5%;
  margin: auto;
}
@media screen and (max-height: 500px) {
  #login-mask {
    padding: 0;
  }
}
@media screen and (min-width: 900px) {
  #login-mask {
    width: 45%;
    padding: 1%;
  }
}
#login-mask form {
  width: 85% !important;
  margin: auto;
  padding: 5%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px;
}
@media screen and (min-width: 1024px) {
  #login-mask form {
    width: 100%;
  }
}
@media screen and (max-height: 500px) {
  #login-mask form {
    padding: 2%;
  }
}
#login-mask input {
  border: solid #a6a6a6 1px;
}
#login-mask select {
  text-align: center !important;
  text-align-last: center !important;
}
@media screen and (max-width: 500px) {
  #login-mask .row {
    display: block !important;
  }
}
#login-mask .row .col {
  width: 100% !important;
}
@media screen and (max-width: 500px) {
  #login-mask .row .col {
    margin-top: 2%;
  }
}
#login-mask .row .col button {
  width: 100% !important;
}

#heimerl-banner {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
  margin: auto;
  padding: 2.5%;
  padding-bottom: 5%;
}
@media screen and (max-height: 500px) {
  #heimerl-banner {
    display: none;
  }
}
.chronicle-entry-table {
  height: 75vh;
  max-height: 75vh !important;
}
#user-roles-modal #project-specific-roles, #user-roles-modal #global-roles {
  overflow: hidden;
}

@import ../../sass/colors

#search
    padding-right: 16px

#search-bar
    user-select: none !important

#search-button
    color: #6c757d
    background-color: $white
    border-color: $white
    border-left: 1px $white solid
    user-select: none !important
@import ../../sass/colors

$linkColor: $white

#footer-navigation
    width: 100%
    padding: 0
    list-style: none
    text-decoration: none

    .nav-item
        padding-left: 1%
        .nav-link
            width: 100% !important
            user-select: none
            cursor: pointer
            padding: 0
            color: $white
            border-bottom: solid $invisible 2px
            transition: all 0.5s ease

            &:hover
                border-bottom: solid $heimerl-red 2px
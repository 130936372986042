@import ../../../sass/colors

#users-list
    padding-left: 10%
    padding-right: 10%
    margin: auto
    max-height: 75vh

    .list-group
        overflow-y: scroll !important
        max-height: 75vh

    .hamburger-bun
        background: transparentize($white, 0.25) !important
    
    .user-enabled
        color: #003728 !important
        background: #b8d5cd !important

    .user-disabled
        color: #5e0b16 !important
        background: #eabdc3 !important